/*
 *  MIXINS
 */
/**
 * From: https://css-tricks.com/between-the-lines/
 *
 * Computes a CSS calc function that betweens a value from
 * A to B over viewport-width A to viewport-width B.
 * Requires a media query to cap the value at B.
 */
/**
 * Mixin to apply base and media queried values
 * for the between @function.
 * Defaults to/from: $small/$large, but can be overridden.
 */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i|Raleway:300,900");
/*
 *  RESETS
 */
/*! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
/* Document (https://html.spec.whatwg.org/multipage/semantics.html#semantics)
   ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: inherit;
  /* 2 */ }

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritence in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */ }

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Add the default cursor in all browsers (opinionated).
 * 3. Prevent font size adjustments after orientation changes in IE and iOS.
 */
html {
  box-sizing: border-box;
  /* 1 */
  cursor: default;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections (https://html.spec.whatwg.org/multipage/semantics.html#sections)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0; }

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: .67em 0; }

/* Grouping content (https://html.spec.whatwg.org/multipage/semantics.html#grouping-content)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in IE.
 */
figcaption,
figure,
main {
  /* 1 */
  display: block; }

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px; }

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none; }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Text-level semantics (https://html.spec.whatwg.org/multipage/semantics.html#text-level-semantics)
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic; }

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ffff00;
  color: #000000; }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

/*
 * Remove the text shadow on text selections (opinionated).
 * 1. Restore the coloring undone by defining the text shadow (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none; }

::selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none; }

/* Embedded content (https://html.spec.whatwg.org/multipage/embedded-content.html#embedded-content)
   ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg {
  fill: currentColor; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Tabular data (https://html.spec.whatwg.org/multipage/tables.html#tables)
   ========================================================================== */
/**
 * Collapse border spacing
 */
table {
  border-collapse: collapse; }

/* Forms (https://html.spec.whatwg.org/multipage/forms.html#forms)
   ========================================================================== */
/**
 * Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  margin: 0; }

/**
 * Inherit styling in all browsers (opinionated).
 */
button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: inherit; }

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none; }

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */ }

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  overflow: auto;
  /* 1 */
  resize: vertical;
  /* 2 */ }

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/* Interactive elements (https://html.spec.whatwg.org/multipage/forms.html#interactive-elements)
   ========================================================================== */
/*
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 */
details,
menu {
  display: block; }

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item; }

/* Scripting (https://html.spec.whatwg.org/multipage/scripting.html#scripting-3)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block; }

/**
 * Add the correct display in IE.
 */
template {
  display: none; }

/* User interaction (https://html.spec.whatwg.org/multipage/interaction.html#editing)
   ========================================================================== */
/*
 * Remove the tapping delay on clickable elements (opinionated).
 * 1. Remove the tapping delay in IE 10.
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation; }

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none; }

/* ARIA (https://w3c.github.io/html-aria/)
   ========================================================================== */
/**
 * Change the cursor on busy elements (opinionated).
 */
[aria-busy="true"] {
  cursor: progress; }

/*
 * Change the cursor on control elements (opinionated).
 */
[aria-controls] {
  cursor: pointer; }

/*
 * Change the display on visually hidden accessible elements (opinionated).
 */
[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute; }

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements (opinionated).
 */
[aria-disabled] {
  cursor: default; }

img {
  max-width: 100%; }

html {
  color: #222;
  font-size: 1em;
  line-height: 1.5; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

/*
 *  MAIN STYLES
 */
body {
  background: #191919;
  color: #eee;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  padding-bottom: 3rem; }

a {
  text-decoration: none; }

.no-break {
  white-space: nowrap; }

.top {
  position: relative;
  height: 75vh;
  background: url(../img/bg-header-wide.jpg) no-repeat center/cover;
  color: #191919;
  transform-origin: left top;
  transform: skewY(-5deg);
  display: flex;
  justify-content: center;
  align-items: center; }
  .top .logo {
    transform: skewY(5deg); }
    .top .logo img {
      max-width: 90vw; }
  .subpage .top {
    height: 300px;
    background: url(../img/bg-header-narrow.jpg) no-repeat center/cover;
    transform: none;
    margin-bottom: 3rem; }
    .subpage .top .logo {
      transform: none;
      text-align: center; }
      .subpage .top .logo img {
        width: calc(100% - 2rem);
        max-width: 400px; }

.scrolldown {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  display: block;
  width: 0;
  height: 0;
  border-top: 8px solid #eee;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  transform: skewY(5deg) translateX(-50%);
  animation: bob 1s infinite; }

@keyframes bob {
  0% {
    bottom: 1rem; }
  50% {
    bottom: 1.5rem; }
  100% {
    bottom: 1rem; } }

@media screen and (min-width: 400px) {
  .main {
    width: calc(100% - 2rem);
    max-width: 1000px;
    margin: 0 auto; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  font-weight: 300;
  text-align: center; }

h1 {
  font-size: 2rem; }

h2 {
  margin-top: 2em;
  font-size: 2rem; }

h3 {
  font-size: 1.5rem; }

@media screen and (max-width: 399px) {
  .lead {
    width: calc(100% - 2rem);
    margin: 0 auto; } }

.lead--howto {
  width: calc(100% - 2rem);
  max-width: 1000px;
  margin: 0 auto; }

.lead {
  position: relative;
  margin-top: 3rem;
  border-top: 1px solid #eee; }

.videos {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column; }
  @media screen and (min-width: 600px) {
    .videos {
      flex-direction: row; } }

.video {
  position: relative;
  width: 100%;
  margin: 0 0 1rem 0; }
  @media screen and (min-width: 600px) {
    .video {
      width: calc(50% - 0.5rem); } }
  .video:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 56.25%; }
  .video iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.langswitcher {
  position: absolute;
  top: -2rem;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  font-size: 0.75rem; }
  @media screen and (min-width: 768px) {
    .langswitcher {
      font-size: 1rem; } }
  @media screen and (max-width: 425px) {
    .langswitcher {
      right: 50%;
      transform: translateX(50%);
      width: 100%;
      justify-content: center; } }
  .langswitcher .lang-group {
    margin: 0 0 0 0.5rem; }
  .langswitcher label {
    display: block;
    padding: 0.25rem 0.25rem;
    cursor: pointer;
    transition: 0.25s;
    box-shadow: 0 0 0 0 #ffe119 inset; }
  .langswitcher input {
    display: none; }
  .langswitcher input:checked ~ label {
    color: #292929;
    border-radius: 0.25rem 0.25rem 0 0;
    box-shadow: 0 0 0 50px #ffe119 inset; }

@media screen and (max-width: 767px) {
  .pc-only {
    display: none; } }

@media screen and (min-width: 768px) {
  .sp-only {
    display: none; } }

.price {
  margin: 2rem auto;
  text-align: center; }
  .price a {
    text-decoration: none;
    color: #eee; }
  .price .theprice {
    background: #ffe119;
    color: black;
    font-size: 1.5rem;
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    padding: 0.5rem;
    border-radius: 0.25rem; }
  .price .buyhere {
    background: #009944;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.15) 100%);
    display: inline-block;
    padding: 0.25em 1.5em;
    font-size: 1.25rem;
    border-radius: 0.25rem; }
  .price .thedeets {
    font-size: 0.875rem; }
  .price img {
    width: 100%;
    margin-bottom: 1rem; }

ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.maps {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4); }
  .maps div {
    width: 100%;
    margin-bottom: 1rem;
    text-align: center; }
    @media screen and (min-width: 500px) {
      .maps div {
        width: calc(50% - 0.5rem); } }
  .maps p {
    margin: 0.5rem 0 0; }

.clubs {
  margin: 2rem 0;
  columns: 2;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  text-align: center; }
  .clubs:after {
    content: '';
    display: block;
    order: 100;
    min-width: 35%;
    max-width: 50%;
    height: 0; }
  .clubs li {
    min-width: 35%;
    max-width: 50%; }
    .clubs li:nth-child(1) {
      order: 1; }
    .clubs li:nth-child(2) {
      order: 3; }
    .clubs li:nth-child(3) {
      order: 5; }
    .clubs li:nth-child(4) {
      order: 7; }
    .clubs li:nth-child(5) {
      order: 9; }
    .clubs li:nth-child(6) {
      order: 2; }
    .clubs li:nth-child(7) {
      order: 4; }
    .clubs li:nth-child(8) {
      order: 6; }
    .clubs li:nth-child(9) {
      order: 8; }
    .clubs li:nth-child(10) {
      order: 10; }
  .clubs a {
    position: relative;
    color: #ffe119;
    font-weight: 600; }
    .clubs a:before {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      width: 0;
      height: 2px;
      background: #ffe119;
      transition: 0.25s; }
    .clubs a:hover:before {
      width: 100%;
      opacity: 1; }

figure {
  margin: 0; }

.clubcards {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap; }
  @media screen and (min-width: 768px) {
    .clubcards {
      justify-content: space-between; } }
  @media screen and (min-width: 774px) {
    .clubcards {
      justify-content: space-around; } }
  @media screen and (min-width: 820px) {
    .clubcards {
      justify-content: center; } }

.club {
  position: relative;
  padding-bottom: 1rem;
  background: #eee;
  max-width: 100%;
  transition: 0.25s;
  color: #191919; }
  @media screen and (min-width: 769px) {
    .club:hover {
      transform: scale(1.05);
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
      z-index: 1; } }
  @media screen and (min-width: 400px) {
    .club {
      border: 2px solid #eee;
      margin-bottom: 1rem; } }
  @media screen and (max-width: 767px) {
    .club:nth-child(even) {
      background: #292929;
      border-color: #292929;
      color: #eee; }
      .club:nth-child(even) .data {
        background: rgba(255, 255, 255, 0.1); }
      .club:nth-child(even) .location p:before, .club:nth-child(even) .location p:after {
        background: #eee; } }
  @media screen and (min-width: 768px) {
    .club {
      max-width: calc(50% - 0.5rem); } }
  @media screen and (min-width: 820px) {
    .club:nth-child(odd) {
      margin-right: 1rem; } }
  .club .photos {
    width: 100%; }
  .club .logo {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.5rem;
    width: 100px;
    height: 100px;
    text-align: left;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%); }
  .club h3 {
    margin: 1rem 0 0.5rem; }
  .club .location {
    text-align: center;
    text-transform: uppercase;
    font-size: 0.675rem;
    font-weight: 400;
    display: flex;
    justify-content: center; }
    .club .location p {
      margin: 0;
      position: relative; }
      .club .location p:before, .club .location p:after {
        position: absolute;
        content: '';
        display: block;
        height: 1px;
        width: 2rem;
        background: #191919;
        top: 50%;
        right: calc(100% + 0.5rem); }
      .club .location p:after {
        right: auto;
        left: calc(100% + 0.5rem); }
  .club .businesshours {
    text-align: center;
    font-size: 0.675rem;
    text-transform: uppercase;
    font-weight: 400;
    margin: 1rem auto;
    max-width: 250px; }
  .club .peaktime {
    margin: -0.75rem auto 1rem;
    text-align: center;
    font-size: 1.25rem; }
  .club .divider {
    transform: scaleY(1.75);
    padding: 0 0.15rem;
    display: inline-block; }
  .club .description {
    margin: 0 1rem; }
  .club .data {
    display: none;
    margin: 0 1rem;
    background: rgba(0, 0, 0, 0.1);
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem; }
    .club .data p {
      margin: 0; }
  .club .link {
    display: block;
    margin: 1rem 1rem 0;
    padding: 0.5em 0.5em;
    border-radius: 0.25rem;
    background: repeating-linear-gradient(45deg, #e10032 0%, #e10032 10px, #c8002c 10px, #c8002c 20px);
    background-size: 125%;
    color: #eee;
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    font-weight: 900;
    text-align: center;
    font-size: 1.25rem;
    animation: 2s glidebg linear infinite;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5) inset; }
    .club .link p {
      margin: 0; }

@keyframes glidebg {
  0% {
    background-position: -28.28427px 0; }
  100% {
    background-position: 0 0; } }

.attention {
  margin-top: 3rem;
  padding: 3rem 1rem 0;
  border-top: 1px solid #eee;
  font-size: 0.875rem; }
  @media screen and (min-width: 768px) {
    .attention {
      columns: 2; } }

footer {
  background: #090909;
  margin: 3rem 0 0;
  padding: 3rem 0;
  font-size: 0.625rem; }
  footer .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }

.logos {
  margin: 1rem 0;
  text-align: center; }
  .logos img {
    margin: 1rem 0.5rem; }

.top-link {
  position: fixed;
  z-index: 5;
  right: 1rem;
  bottom: -3rem;
  display: block;
  background: #eee;
  background: rgba(150, 150, 150, 0.75);
  color: #191919;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.5em;
  border-radius: 0.25em;
  opacity: 1;
  transition: 0.25s; }
  .top-link.show {
    right: 1rem;
    bottom: 5rem; }
  .top-link:hover {
    opacity: 1; }
  .top-link:before {
    content: '';
    display: block;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 5px solid rgba(150, 150, 150, 0.75);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent; }

.onr {
  width: 600px;
  max-width: calc(100% - 2rem);
  margin: 0 auto; }
  .onr a {
    color: #eee; }

.onrlogo {
  height: 1.5em;
  vertical-align: bottom;
  margin: 0 0.5rem; }

.howtophotos {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 3rem 0; }
  .howtophotos > a,
  .howtophotos > img {
    width: calc((100% - 1rem) / 2);
    margin: 0 0.25rem; }
  .howtophotos > *:first-child {
    width: 50%;
    margin: 0 2rem 1rem 2rem; }
  @media screen and (min-width: 450px) {
    .howtophotos {
      flex-wrap: nowrap;
      justify-content: space-between; }
      .howtophotos > a,
      .howtophotos > img {
        width: calc((100% - 2rem) / 3);
        margin: 0; }
      .howtophotos > *:first-child {
        width: calc((100% - 2rem) / 3);
        margin: 0; } }

.linkhowtouse {
  display: block;
  margin: 4rem auto;
  width: calc(100% - 2rem);
  background: #e6d200;
  background: #f0dc00;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.15) 100%);
  padding: 1em 1em;
  font-size: 1.5rem;
  border-radius: 0.25rem;
  letter-spacing: 1px;
  text-align: center;
  color: #191919;
  font-weight: bold;
  text-transform: uppercase;
  transition: 0.25s;
  box-shadow: 0 0 0.25rem #ffe119, -8px -8px 0 #191919, 8px -8px 0 #191919, 8px 8px 0 #191919, -8px 8px 0 #191919, -10px -10px 0 #ffe119, 10px -10px 0 #ffe119, 10px 10px 0 #ffe119, -10px 10px 0 #ffe119, 0 0 0.5rem 10px #ffe119; }
  @media screen and (max-width: 550px) {
    .linkhowtouse {
      font-size: 1.25rem; } }
  .linkhowtouse:hover {
    background-color: #b4a000; }

.page-howto h2 {
  position: relative;
  font-size: 1.5rem;
  line-height: 2;
  padding-bottom: 1rem; }
  .page-howto h2:before {
    content: '';
    position: absolute;
    display: block;
    width: calc(100% - 2rem);
    max-width: 320px;
    height: 1px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, white 50%, rgba(0, 0, 0, 0) 100%);
    top: 100%;
    left: 50%;
    transform: translateX(-50%); }

.page-howto .lead h3 {
  margin-top: 3rem;
  font-size: 1.25rem; }

.buyhere-locations {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; }
  @media screen and (max-width: 399px) {
    .buyhere-locations {
      justify-content: center; } }
  .buyhere-locations a {
    width: calc(50% - 0.5rem);
    margin-bottom: 1rem; }
    @media screen and (max-width: 550px) {
      .buyhere-locations a {
        width: 100%; } }
    @media screen and (max-width: 399px) {
      .buyhere-locations a {
        width: calc(100% - 1rem); } }
  .buyhere-locations img {
    margin-bottom: 0; }

.number {
  position: relative;
  top: -0.03em;
  display: inline-block;
  font-size: 2em;
  vertical-align: middle;
  font-family: sans-serif;
  line-height: 1;
  font-weight: bold; }
  h2 .number {
    color: #ffe84c; }
  h3 .number {
    font-weight: normal;
    color: #e5c700; }

.course-title {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap; }

.transfer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1;
  margin: 0 1rem;
  color: #50b233;
  padding-bottom: 0.1em; }
  .transfer .arrow {
    font-size: 1.25em;
    line-height: 0.75; }
  .transfer .minutes {
    font-size: 0.67rem;
    white-space: nowrap; }

.course:nth-child(even) {
  background: rgba(255, 255, 255, 0.1);
  margin: 3rem 0 0;
  padding: 0.5rem 0 2rem; }

.course > .inner {
  width: calc(100% - 2rem);
  max-width: 1000px;
  margin: 0 auto; }

.finalnote {
  width: calc(100% - 2rem);
  max-width: 768px;
  margin: 5rem auto 0; }

.contact {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  max-width: 1200px;
  z-index: 10;
  margin: 0 auto; }
  .contact__list {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: stretch; }
  .contact__item {
    width: 20%; }
    .contact__item::before {
      content: '\200B';
      display: block;
      width: 0;
      height: 0;
      overflow: hidden; }
  .contact__link {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4rem;
    color: #fff;
    text-decoration: none;
    font-size: 2rem;
    line-height: 1;
    text-align: center;
    transition: 0.25s; }
    .contact__link--whatsapp::before, .contact__link--email::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      width: 0;
      height: 0;
      border-bottom: 4rem solid #1fbea5;
      border-left: 4rem solid transparent;
      border-right: 4rem solid transparent;
      transform: translateX(-50%);
      transition: border 0.25s; }
    @media screen and (min-width: 1330px) {
      .contact__link--whatsapp::before, .contact__link--email::before {
        display: block; } }
    .contact__link--email::before {
      left: auto;
      right: 0;
      transform: translateX(50%);
      border-bottom-color: #129f94; }
    .contact__link--whatsapp {
      background: #1fbea5; }
      @media screen and (min-width: 600px) {
        .contact__link--whatsapp:hover {
          background: #18927f; }
          .contact__link--whatsapp:hover::before {
            border-bottom-color: #18927f; } }
    .contact__link--line {
      background: #00b902; }
      @media screen and (min-width: 600px) {
        .contact__link--line:hover {
          background: #008601; } }
    .contact__link--kakao {
      background: #ffda2e; }
      @media screen and (min-width: 600px) {
        .contact__link--kakao:hover {
          background: #c7a400; } }
    .contact__link--wechat {
      background: #2cc102; }
      @media screen and (min-width: 600px) {
        .contact__link--wechat:hover {
          background: #208f01; } }
    .contact__link--email {
      background: #129f94; }
      @media screen and (min-width: 600px) {
        .contact__link--email:hover {
          background: #0d7169; }
          .contact__link--email:hover::before {
            border-bottom-color: #0d7169; } }
    .contact__link span {
      position: absolute;
      top: 105%; }
  .contact__popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.25s; }
    .contact__popup__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.7); }
    .contact__popup__close {
      position: absolute;
      overflow: hidden;
      top: 0;
      right: 0;
      display: block;
      appearance: none;
      background: black;
      border: 2px solid white;
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      transform: translate(25%, -25%); }
      .contact__popup__close::before, .contact__popup__close::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 1.25rem;
        height: 0.15rem;
        background: white;
        transform: translate(-50%, -50%) rotate(45deg); }
      .contact__popup__close::after {
        transform: translate(-50%, -50%) rotate(-45deg); }
      .contact__popup__close span {
        position: absolute;
        top: -500px; }
    .contact__popup > .inner {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      z-index: 20;
      width: 100%;
      max-width: 300px;
      padding: 1.5rem 1rem;
      background: white;
      color: #222;
      border-radius: 0.25rem;
      text-align: center; }
    .contact__popup h3 {
      margin: 0; }
      .contact__item--line .contact__popup h3 i {
        color: #00b902; }
      .contact__item--wechat .contact__popup h3 i {
        color: #2cc102; }
    .contact__popup img {
      height: 1em; }
    .contact__popup p {
      background: rgba(0, 0, 0, 0.1);
      padding: 0.5em 1em;
      font-size: 1.25rem;
      border-radius: 0.25em;
      font-family: 'courier new', monospace; }
    .contact__popup__copy {
      position: relative;
      appearance: none;
      background-color: #abe9cd;
      background-image: linear-gradient(315deg, #abe9cd 0%, #2e9dbf 74%);
      border: 0;
      border-radius: 2rem;
      color: #fff;
      padding: 0.5rem 1rem;
      transform: scale(1);
      transition: transform 0.25s; }
      @media screen and (min-width: 768px) {
        .contact__popup__copy:hover {
          transform: scale(1.05); } }
      .contact__popup__copy.did-copy::before {
        content: 'Copied!';
        position: absolute;
        top: 25%;
        left: 50%;
        padding: 0.5em 1em;
        font-size: 0.67rem;
        background: #fff;
        color: #222;
        border-radius: 5rem;
        transform: translate(-50%, -50%);
        box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
        animation: 1s fadeup linear 1;
        animation-fill-mode: forwards; }
    .contact__popup.is-active {
      display: flex; }
    .contact__popup.is-visible {
      opacity: 1; }
  .contact img {
    max-height: 2rem; }

@keyframes fadeup {
  from {
    transform: translate(-50%, -50%);
    opacity: 1; }
  to {
    transform: translate(-50%, -150%);
    opacity: 0; } }

/*
 *  OTHER STYLES
 */
.clearfix:after {
  content: "";
  display: block;
  clear: both; }

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   http://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */
@media print {
  *,
  *:before,
  *:after,
  *:first-letter,
  *:first-line {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster:
                                   http://www.sanbeiji.com/archives/953 */
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: ""; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }
