$dark: #191919;
$darker: #090909;
$medium: #292929;
$light: #eee;
$contrast: rgba(135, 255, 180, 1);
$contrast: rgba(255, 225, 25, 1);
$contrast2: rgba(255, 135, 180, 1);
$contrast2: rgba(225, 0, 50, 1);

$whatsapp: #1fbea5;
$line: #00b902;
$kakao: #ffda2e;
$wechat: #2cc102;
$email: #129f94;

body {
  background: $dark;
  color: $light;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  padding-bottom: 3rem;
}

a {
  text-decoration: none;
}

.no-break {
  white-space: nowrap;
}

.top {
  position: relative;
  height: 75vh;
  background: url(../img/bg-header-wide.jpg) no-repeat center/cover;
  color: $dark;
  transform-origin: left top;
  transform: skewY(-5deg);
  display: flex;
  justify-content: center;
  align-items: center;
  .logo {
    transform: skewY(5deg);
    img {
      max-width: 90vw;
    }
  }
  .subpage & {
    height: 300px;
    background: url(../img/bg-header-narrow.jpg) no-repeat center/cover;
    transform: none;
    margin-bottom: 3rem;
    .logo {
      transform: none;
      text-align: center;
      img {
        width: calc(100% - 2rem);
        max-width: 400px;
      }
    }
  }
  // &:after {
  //   position: absolute;
  //   bottom: 1rem;
  //   left: 50%;
  //   content: "";
  //   display: block;
  //   width: 0; height: 0;
  //   border-top: 8px solid $light;
  //   border-left: 8px solid transparent;
  //   border-right: 8px solid transparent;
  //   transform: skewY(6deg) translateX(-50%);
  //   animation: bob 1s infinite;
  // }
}

.scrolldown {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  display: block;
  width: 0;
  height: 0;
  border-top: 8px solid $light;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  transform: skewY(5deg) translateX(-50%);
  animation: bob 1s infinite;
}

@keyframes bob {
  0% {
    bottom: 1rem;
  }
  50% {
    bottom: 1.5rem;
  }
  100% {
    bottom: 1rem;
  }
}

.main {
  // text-align: justify;
  @include min(400) {
    width: calc(100% - 2rem);
    max-width: 1000px;
    margin: 0 auto;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  font-weight: 300;
  text-align: center;
}

h1 {
  font-size: 2rem;
}

h2 {
  margin-top: 2em;
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

.lead {
  @include max(399) {
    width: calc(100% - 2rem);
    margin: 0 auto;
  }

  &--howto {
    width: calc(100% - 2rem);
    max-width: 1000px;
    margin: 0 auto;
  }
}

.lead {
  position: relative;
  margin-top: 3rem;
  border-top: 1px solid $light;
}

.videos {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  @include min(600) {
    flex-direction: row;
  }
}

.video {
  position: relative;

  width: 100%;
  margin: 0 0 1rem 0;

  @include min(600) {
    width: calc(50% - 0.5rem);
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 56.25%;
  }

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.langswitcher {
  position: absolute;
  top: -2rem;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  font-size: 0.75rem;
  @include min(768) {
    font-size: 1rem;
  }
  @include max(425) {
    right: 50%;
    transform: translateX(50%);
    width: 100%;
    justify-content: center;
  }
  .lang-group {
    margin: 0 0 0 0.5rem;
  }
  label {
    display: block;
    padding: 0.25rem 0.25rem;
    cursor: pointer;
    transition: 0.25s;
    box-shadow: 0 0 0 0 $contrast inset;
  }
  input {
    display: none;
  }
  input:checked ~ label {
    // background: $contrast;
    color: $medium;
    border-radius: 0.25rem 0.25rem 0 0;
    box-shadow: 0 0 0 50px $contrast inset;
  }
}

.pc-only {
  @include max(767) {
    display: none;
  }
}

.sp-only {
  @include min(768) {
    display: none;
  }
}

.price {
  // width: calc(100% - 1rem);
  // max-width: 400px;
  margin: 2rem auto;
  text-align: center;
  a {
    text-decoration: none;
    color: $light;
  }
  .theprice {
    background: $contrast;
    color: black;
    font-size: 1.5rem;
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    padding: 0.5rem;
    border-radius: 0.25rem;
  }
  .buyhere {
    background: rgba(0, 153, 68, 1);
    background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0) 50%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0.15) 100%
    );
    display: inline-block;
    padding: 0.25em 1.5em;
    font-size: 1.25rem;
    border-radius: 0.25rem;
  }
  .thedeets {
    font-size: 0.875rem;
  }
  img {
    width: 100%;
    margin-bottom: 1rem;
  }
}

// .contact {
//   width: 100%;
//   max-width: 400px;
//   margin: 0 auto;
// }

// .contact {
//   max-width: none;
//   padding: 1rem;
//   background: linear-gradient(145deg, rgba(0, 0, 0, 0.55) 0, rgba(0, 0, 0, 0) 100%), rgba(255, 255, 255, 0.1);
//   border: 0.25rem solid $contrast;
//   border-radius: 1rem;
// }

// .contactrow {
//   border-bottom: 1px solid rgba(255, 255, 255, 0.2);
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 0.5rem 0;

//   img {
//     width: 36px;
//     height: 36px;
//     margin-right: 1rem;
//   }
// }

// .contactinfo {
//   a {
//     color: $contrast;
//     text-decoration: underline;
//   }
// }

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.maps {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);

  div {
    width: 100%;
    margin-bottom: 1rem;
    text-align: center;

    @include min(500) {
      width: calc(50% - 0.5rem);
    }
  }
  p {
    margin: 0.5rem 0 0;
  }

  // svg {
  //   color: green;
  // }

  // img {
  // width: 100%;
  // margin-bottom: 1rem;
  // @include min(500) {
  //   width: calc(50% - 0.5rem);
  // }
  // }
}

.clubs {
  margin: 2rem 0;
  columns: 2;
  display: flex;
  // justify-content: space-around;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  text-align: center;
  &:after {
    content: '';
    display: block;
    order: 100;
    min-width: 35%;
    max-width: 50%;
    height: 0;
  }
  li {
    min-width: 35%;
    max-width: 50%;
    &:nth-child(1) {
      order: 1;
    }
    &:nth-child(2) {
      order: 3;
    }
    &:nth-child(3) {
      order: 5;
    }
    &:nth-child(4) {
      order: 7;
    }
    &:nth-child(5) {
      order: 9;
    }
    &:nth-child(6) {
      order: 2;
    }
    &:nth-child(7) {
      order: 4;
    }
    &:nth-child(8) {
      order: 6;
    }
    &:nth-child(9) {
      order: 8;
    }
    &:nth-child(10) {
      order: 10;
    }
  }
  a {
    position: relative;
    color: $contrast;
    font-weight: 600;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      width: 0;
      height: 2px;
      background: $contrast;
      // opacity: 0;
      transition: 0.25s;
    }
    &:hover {
      &:before {
        width: 100%;
        opacity: 1;
      }
    }
  } // a
} // .clubs

figure {
  margin: 0;
}

.clubcards {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  @include min(768) {
    justify-content: space-between;
  }
  @include min(774) {
    justify-content: space-around;
  }
  @include min(820) {
    justify-content: center;
  }
}

.club {
  position: relative;
  padding-bottom: 1rem;
  background: $light;
  max-width: 100%;

  transition: 0.25s;

  @include min(769) {
    &:hover {
      transform: scale(1.05);
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
      z-index: 1;
    }
  }

  color: $dark;

  @include min(400) {
    border: 2px solid $light;
    margin-bottom: 1rem;
  }

  @include max(767) {
    &:nth-child(even) {
      background: $medium;
      border-color: $medium;
      color: $light;
      .data {
        background: rgba(255, 255, 255, 0.1);
      }
      .location p {
        &:before,
        &:after {
          background: $light;
        }
      }
    } // nth-child even
  } // max 767

  @include min(768) {
    max-width: calc(50% - 0.5rem);
  }
  @include min(820) {
    &:nth-child(odd) {
      margin-right: 1rem;
    }
  }

  .photos {
    width: 100%;
  }

  .logo {
    position: absolute;
    // top: 0.5rem;
    // left: 0.5rem;
    top: 0;
    left: 0;
    padding: 0.5rem;
    width: 100px;
    height: 100px;
    text-align: left;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
  }

  h3 {
    margin: 1rem 0 0.5rem;
    // font-weight: bold;
  }

  .location {
    text-align: center;
    text-transform: uppercase;
    font-size: 0.675rem;
    font-weight: 400;
    display: flex;
    justify-content: center;
    p {
      margin: 0;
      position: relative;
      &:before,
      &:after {
        position: absolute;
        content: '';
        display: block;
        height: 1px;
        width: 2rem;
        background: $dark;
        top: 50%;
        right: calc(100% + 0.5rem);
      }
      &:after {
        right: auto;
        left: calc(100% + 0.5rem);
      }
    }
  } // .location

  .businesshours {
    text-align: center;
    font-size: 0.675rem;
    text-transform: uppercase;
    font-weight: 400;
    margin: 1rem auto;
    max-width: 250px;
  }

  .peaktime {
    margin: -0.75rem auto 1rem;
    text-align: center;
    font-size: 1.25rem;
    // font-weight: 600;
  }

  .divider {
    // font-size: 2em;
    // font-weight: 200;
    // vertical-align: middle;
    transform: scaleY(1.75);
    padding: 0 0.15rem;
    display: inline-block;
  }

  .description {
    margin: 0 1rem;
  }

  .data {
    display: none;
    margin: 0 1rem;
    background: rgba(0, 0, 0, 0.1);
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    p {
      margin: 0;
    }
  } // .data

  .link {
    display: block;
    margin: 1rem 1rem 0;
    padding: 0.5em 0.5em;
    border-radius: 0.25rem;
    // background: repeating-linear-gradient(45deg, rgba(225,0,0,1) 0%, rgba(225,0,0,1) 10px, rgba(200,0,0,1) 10px, rgba(200,0,0,1) 20px);
    background: repeating-linear-gradient(45deg, $contrast2 0%, $contrast2 10px, darken($contrast2, 5%) 10px, darken($contrast2, 5%) 20px);
    background-size: 125%;
    color: $light;
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    font-weight: 900;
    text-align: center;
    font-size: 1.25rem;
    animation: 2s glidebg linear infinite;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5) inset;
    p {
      margin: 0;
    }
  }
} // .club

@keyframes glidebg {
  0% {
    background-position: #{sin(45deg) * -40}px 0;
  }
  100% {
    background-position: 0 0;
  }
}

.attention {
  margin-top: 3rem;
  padding: 3rem 1rem 0;
  border-top: 1px solid $light;
  font-size: 0.875rem;
  @include min(768) {
    columns: 2;
  }
}

footer {
  background: $darker;
  margin: 3rem 0 0;
  padding: 3rem 0;
  font-size: 0.625rem;
  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.logos {
  margin: 1rem 0;
  text-align: center;
  img {
    margin: 1rem 0.5rem;
    // max-height: 60px;
  }
}

.top-link {
  position: fixed;
  z-index: 5;
  right: 1rem;
  bottom: -3rem;
  display: block;
  background: $light;
  background: rgba(150, 150, 150, 0.75);
  color: $dark;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.5em;
  border-radius: 0.25em;
  opacity: 1;
  transition: 0.25s;
  &.show {
    right: 1rem;
    bottom: 5rem;
  }
  &:hover {
    opacity: 1;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    // border-bottom: 5px solid $light;
    border-bottom: 5px solid rgba(150, 150, 150, 0.75);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
}

.onr {
  width: 600px;
  max-width: calc(100% - 2rem);
  margin: 0 auto;
  a {
    color: #eee;
  }
}

.onrlogo {
  height: 1.5em;
  vertical-align: bottom;
  margin: 0 0.5rem;
}

.howtophotos {
  // display: flex;
  // justify-content: space-between;
  // align-items: flex-start;
  // margin: 3rem 0;
  // & > a, & > img {
  //   width: calc((100% - 2rem) / 3);
  // }
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 3rem 0;
  & > a,
  & > img {
    width: calc((100% - 1rem) / 2);
    margin: 0 0.25rem;
  }
  & > *:first-child {
    width: 50%;
    margin: 0 2rem 1rem 2rem;
  }
  @include min(450) {
    flex-wrap: nowrap;
    justify-content: space-between;
    & > a,
    & > img {
      width: calc((100% - 2rem) / 3);
      margin: 0;
    }
    & > *:first-child {
      width: calc((100% - 2rem) / 3);
      margin: 0;
    }
  }
}

.linkhowtouse {
  display: block;
  margin: 4rem auto;
  width: calc(100% - 2rem);
  // max-width: 500px;
  background: rgba(230, 210, 0, 1);
  background: rgba(240, 220, 0, 1);
  // background: rgba(0,67,168,1);
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.15) 100%);
  padding: 1em 1em;
  font-size: 1.5rem;
  border-radius: 0.25rem;
  letter-spacing: 1px;
  text-align: center;
  color: $dark;
  font-weight: bold;
  text-transform: uppercase;
  transition: 0.25s;
  // color: $light;
  // text-shadow: 0 1px 0 rgba(255,255,255,0.65);
  // border: 5px solid rgba(255,255,255,0.25);

  box-shadow: 0 0 0.25rem $contrast, -8px -8px 0 $dark, 8px -8px 0 $dark, 8px 8px 0 $dark, -8px 8px 0 $dark, -10px -10px 0 $contrast,
    10px -10px 0 $contrast, 10px 10px 0 $contrast, -10px 10px 0 $contrast, 0 0 0.5rem 10px $contrast;
  @include max(550) {
    font-size: 1.25rem;
  }
  &:hover {
    background-color: rgba(180, 160, 0, 1);
  }
}

.page-howto {
  h2 {
    position: relative;
    font-size: 1.5rem;
    line-height: 2;
    padding-bottom: 1rem;

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: calc(100% - 2rem);
      max-width: 320px;
      height: 1px;
      background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(255, 255, 255, 1) 50%, rgba(0, 0, 0, 0) 100%);
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .lead {
    h3 {
      margin-top: 3rem;
      font-size: 1.25rem;
    }
  }
}

.buyhere-locations {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  @include max(399) {
    justify-content: center;
  }
  a {
    width: calc(50% - 0.5rem);
    margin-bottom: 1rem;
    @include max(550) {
      width: 100%;
    }
    @include max(399) {
      width: calc(100% - 1rem);
    }
  }
  img {
    margin-bottom: 0;
  }
}

.number {
  position: relative;
  top: -0.03em;
  display: inline-block;
  font-size: 2em;
  vertical-align: middle;
  font-family: sans-serif;
  line-height: 1;
  font-weight: bold;

  // &:after {}

  // &--one {}
  // &--two {}
  // &--three {}

  h2 & {
    color: lighten($contrast, 10);
  }

  h3 & {
    font-weight: normal;
    color: darken($contrast, 10);
  }
}

.course-title {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
}

.transfer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1;
  margin: 0 1rem;
  color: #50b233;
  padding-bottom: 0.1em;

  .arrow {
    font-size: 1.25em;
    line-height: 0.75;
  }

  .minutes {
    font-size: 0.67rem;
    white-space: nowrap;
  }
}

.courses {
}

.course {
  &:nth-child(even) {
    background: rgba(255, 255, 255, 0.1);
    margin: 3rem 0 0;
    padding: 0.5rem 0 2rem;
  }

  > .inner {
    width: calc(100% - 2rem);
    max-width: 1000px;
    margin: 0 auto;
  }
}

.finalnote {
  // text-align: center;
  width: calc(100% - 2rem);
  max-width: 768px;
  margin: 5rem auto 0;
}

.contact {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  max-width: 1200px;
  z-index: 10;
  margin: 0 auto;

  &__list {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  &__item {
    width: 20%;
    &::before {
      content: '\200B';
      display: block;
      width: 0;
      height: 0;
      overflow: hidden;
    }
  }

  &__link {
    position: relative;
    // overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4rem;
    color: #fff;
    text-decoration: none;
    font-size: 2rem;
    line-height: 1;
    text-align: center;
    transition: 0.25s;

    &--whatsapp::before,
    &--email::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      width: 0;
      height: 0;
      border-bottom: 4rem solid $whatsapp;
      border-left: 4rem solid transparent;
      border-right: 4rem solid transparent;
      transform: translateX(-50%);
      transition: border 0.25s;
    }

    @include min(1330) {
      &--whatsapp::before,
      &--email::before {
        display: block;
      }
    }

    &--email::before {
      left: auto;
      right: 0;
      transform: translateX(50%);
      border-bottom-color: $email;
    }

    &--whatsapp {
      background: $whatsapp;

      @include min(600) {
        &:hover {
          background: darken($whatsapp, 10);

          &::before {
            border-bottom-color: darken($whatsapp, 10);
          }
        }
      }
    }

    &--line {
      background: $line;

      @include min(600) {
        &:hover {
          background: darken($line, 10);
        }
      }
    }

    &--kakao {
      background: $kakao;

      @include min(600) {
        &:hover {
          background: darken($kakao, 20);
        }
      }
    }

    &--wechat {
      background: $wechat;

      @include min(600) {
        &:hover {
          background: darken($wechat, 10);
        }
      }
    }

    &--email {
      background: $email;

      @include min(600) {
        &:hover {
          background: darken($email, 10);

          &::before {
            border-bottom-color: darken($email, 10);
          }
        }
      }
    }

    span {
      position: absolute;
      top: 105%;
    }
  }

  &__popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.25s;

    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.7);
    }

    &__close {
      position: absolute;
      overflow: hidden;
      top: 0;
      right: 0;
      display: block;
      appearance: none;
      background: black;
      border: 2px solid white;
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      transform: translate(25%, -25%);

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 1.25rem;
        height: 0.15rem;
        background: white;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      span {
        position: absolute;
        top: -500px;
      }
    }

    > .inner {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      z-index: 20;
      width: 100%;
      max-width: 300px;
      padding: 1.5rem 1rem;
      background: white;
      color: #222;
      border-radius: 0.25rem;
      text-align: center;
    }

    h3 {
      margin: 0;

      .contact__item--line & i {
        color: $line;
      }
      .contact__item--wechat & i {
        color: $wechat;
      }
    }

    img {
      height: 1em;
    }

    p {
      background: rgba(0, 0, 0, 0.1);
      padding: 0.5em 1em;
      font-size: 1.25rem;
      border-radius: 0.25em;
      font-family: 'courier new', monospace;
    }

    &__copy {
      position: relative;
      appearance: none;
      background-color: #abe9cd;
      background-image: linear-gradient(315deg, #abe9cd 0%, #2e9dbf 74%);
      border: 0;
      border-radius: 2rem;
      color: #fff;
      padding: 0.5rem 1rem;
      transform: scale(1);
      transition: transform 0.25s;

      @include min(768) {
        &:hover {
          transform: scale(1.05);
        }
      }

      &.did-copy {
        &::before {
          content: 'Copied!';
          position: absolute;
          top: 25%;
          left: 50%;
          padding: 0.5em 1em;
          font-size: 0.67rem;
          background: #fff;
          color: #222;
          border-radius: 5rem;
          transform: translate(-50%, -50%);
          box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
          animation: 1s fadeup linear 1;
          animation-fill-mode: forwards;
        }
      }
    }

    &.is-active {
      display: flex;
    }

    &.is-visible {
      opacity: 1;
    }
  }

  img {
    max-height: 2rem;
  }
}

@keyframes fadeup {
  from {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  to {
    transform: translate(-50%, -150%);
    opacity: 0;
  }
}
