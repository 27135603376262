// min-width
@mixin min($width) {
  @media screen and (min-width: $width + px) {
    @content;
  }
}

// max-width
@mixin max($width) {
  @media screen and (max-width: $width + px) {
    @content;
  }
}

// min-width and max-width
@mixin minmax($min-width, $max-width) {
  @media only screen and (min-width: $min-width + px) and (max-width: $max-width + px) {
    @content;
  }
}

@mixin portrait {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation : portrait) {
    @content;
  }
}

@mixin landscape {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation : landscape) {
    @content;
  }
}

// min-width and max-width and orientation (portrait or landscape)
@mixin device($min-width, $max-width,$orient) {
  @media only screen and (min-width: $min-width + px) and (max-width: $max-width + px) and (orientation : $orient){
    @content;
  }
}

/**
 * From: https://css-tricks.com/between-the-lines/
 *
 * Computes a CSS calc function that betweens a value from
 * A to B over viewport-width A to viewport-width B.
 * Requires a media query to cap the value at B.
 */

@function between($from, $to, $fromWidth, $toWidth) {
  $slope: ($from - $to) / ($fromWidth - $toWidth);
  $base: $to - $slope * $toWidth;

  @return calc(#{$base} + #{100vw * $slope});
}

$small: 320px;
$large: 1280px;

/**
 * Mixin to apply base and media queried values
 * for the between @function.
 * Defaults to/from: $small/$large, but can be overridden.
 */

@mixin between($property, $from, $to, $fromWidth: $small, $toWidth: $large) {
  #{$property}: $from;

  @media (min-width: $fromWidth) {
    #{$property}: between($from, $to, $small, $large);
  }

  @media (min-width: $toWidth) {
    #{$property}: $to;
  }
}
