/*
 *  MIXINS
 */

// @import "bourbon";
@import "media-queries";
@import "math";

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i|Raleway:300,900');

/*
 *  RESETS
 */

@import "reset";

img {
  max-width: 100%;
}

// Some more resets

html {
  color: #222;
  font-size: 1em;
  // font-family: sans-serif;
  // font-family: "Noto Sans Japanese","ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  // font-weight: 200;
  line-height: 1.5;
}

// A better looking default horizontal rule

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

 // Remove default fieldset styles.

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
 *  MAIN STYLES
 */

 @import "base";

/*
 *  OTHER STYLES
 */

@import "helpers";
@import "print";
